const map = {
    variable_1: '#1',
    init() {
        this.events();
    },
    events() {
        /**
         * Execute events
         */
        this.executeMap();
    },    
    executeMap() {  
        var self = this; 
        var default_area = jQuery('.mtf-select select').attr('data-default');
        if(default_area !== 'null'){
            self.changeMap(default_area);
            jQuery('.mtf-select select').val(default_area).change();
        }
        // Select
        jQuery('.mtf-select').on('change', function(){
            var value = jQuery('.mtf-select').find(":selected").val();           
            self.changeMap(value);
        });        
        jQuery('.mtf-map path').on('click', function(){
            var value = jQuery(this).attr('id');
            self.changeMap(value);
        });
    },
    changeMap(value) {  
        console.log(value);
        jQuery('.mtf-map-content').find('.select-area').css({
            display: 'none'
        });   
        jQuery('.map').find('path').attr('style', '');
        jQuery('.mtf-area-content').removeClass('show-area');
        jQuery('.mtf-select option[value='+value+']').prop('selected', true);
        jQuery('.map').find('[id="'+value+'"]').css({
            fill: '#000000'
        });   
        jQuery('.mtf-map-content').find('[data-area='+value+']').addClass('show-area');
        if(value=='none' || jQuery('.mtf-map-content').find('[data-area='+value+']').length === 0){
            jQuery('.mtf-map-content').find('.select-area').css({
                display: 'block'
            });  
            jQuery('.mtf-select option[value=none]').prop('selected', true);
        }
    }
};
jQuery(() => {
    map.init();
});